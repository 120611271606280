// Badge outlined variations
@each $color, $value in $theme-colors {
  .badge-outline#{$color} {
    @include badge-outline-variatons($value);
  }
}

.badge {
  padding: 12px 14px 11px;
  font-size: 0.8rem;
}
// Booststrap functions and variables
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// Template variables
@import './variables';


// SCSS compass funtions
@import '~compass-mixins/lib/compass';
@import '~compass-mixins/lib/animate';

// Bootstrap main SCSS
@import '~bootstrap/scss/bootstrap.scss';

// Template mixins
@import './mixins/animation';
@import './mixins/badges';
@import './mixins/blockquote';
@import './mixins/breadcrumbs';
@import './mixins/buttons';
@import './mixins/cards';
@import './mixins/misc';
@import './mixins/no_ui_slider';
@import './mixins/pagination';
@import './mixins/popovers';
@import './mixins/tooltips';
@import './mixins/width';

// Core styles
@import './background';
@import './reset';
@import './functions'; 
@import './footer';
@import './misc';
@import './utilities';
@import './demo';
@import './typography';
@import './spinner';

@import './vertical-wrapper';
@import './navbar';
@import './sidebar';
@import './layouts';

// components
@import "./components/accordions";
@import "./components/badges";
@import "./components/bootstrap-alert";
@import "./components/breadcrumbs";
@import "./components/buttons";
@import "./components/cards";
@import "./components/carousel";
@import "./components/checkbox-radio";
@import "./components/dashboard";
@import "./components/datepicker";
@import "./components/dropdown";
@import "./components/forms";
@import "./components/icons";
@import "./components/input-group";
@import "./components/list-group";
@import "./components/modal";
@import "./components/nav";
@import "./components/pagination";
@import "./components/popover";
@import "./components/profile";
@import "./components/rating";
@import "./components/tables";
@import "./components/timeline";
@import "./components/chat";
@import "./components/faq";
@import "./components/auth";

// Email
@import "./components/email/inbox";
@import "./components/email/email-read";
@import "./components/email/email-compose";

// Plugin styles
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "~highlight.js/styles/googlecode.css";
// @import url("//at.alicdn.com/t/font_o5hd5vvqpoqiwwmi.css"); //(feather-font). github https://github.com/AT-UI/feather-font
@import "../assets/fonts/feather/style.css";
@import "../assets/fonts/fontawesome/css/all.min.css";

@import "~flag-icon-css/css/flag-icon.min.css";
@import "~@mdi/font/css/materialdesignicons.min.css";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~animate.css/animate.min.css';
// @import '~@fullcalendar/core/main.min.css';
// @import '~@fullcalendar/daygrid/main.min.css';
// @import '~@fullcalendar/timegrid/main.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~simplemde/dist/simplemde.min.css';
@import "~metismenujs/scss/metismenujs";
@import '~dropzone/dist/min/dropzone.min.css';
@import '~quill/dist/quill.snow.css';
@import '~angular-archwizard/archwizard';
@import '~simple-datatables/dist/style.css';


// Plugin overrides
// @import "./components/plugin-overrides/full-calendar";
@import "./components/plugin-overrides/ng-select";
@import "./components/plugin-overrides/ngx-chips";
@import "./components/plugin-overrides/ngx-quill";
@import "./components/plugin-overrides/perfect-scrollbar";
@import "./components/plugin-overrides/simplemde";
@import "./components/plugin-overrides/sweet-alert";
@import "./components/plugin-overrides/dropzone";
@import "./components/plugin-overrides/angular-archwizard";
@import "./components/plugin-overrides/apex-charts";
@import "./components/plugin-overrides/data-tables";

@import "./projectRoleStatus.scss";

.hanna-logo{
  // width: 25%;
}

.avatar{
  display: inline-block;
  border-radius: 50%;
  width: 50px;    
}

.hana-title{  
  vertical-align: top;
  align-items: start !important;
  margin-bottom: 0px;
  margin-top: 0px;
}

.hana-subtitle{
  padding-top: 10px;
}

.ngb-tp button{
  box-shadow: none !important;
}

button.calendar {
  box-shadow: none !important;
  padding: 0.6rem 2rem;
}

.first-letter-capitalize{
  text-transform: none !important;
}

.first-letter-capitalize:first-letter{
  text-transform: capitalize !important;
}

.sidebar {
    .sidebar-header {
      background: $sidebar-dark-bg;
      border-bottom: 1px solid $sidebar-dark-border-color;
      border-right: 1px solid $sidebar-dark-border-color;
      .sidebar-brand {
        color: #f8f9fc;
      }
      .sidebar-toggler {
        span {
          background: #9fa2ab;
        }
      }
    }
    .sidebar-body {
      background: $sidebar-dark-bg;
      border-right: 1px solid $sidebar-dark-border-color;
      .sidebar-nav {
        .nav-item {
          &.nav-category {
            color: $white;
          }
          .nav-link {
            color: #bfc3ce;
            svg {
              fill: none;
            }
          }
          &:hover,
          &.mm-active {
            > .nav-link {
              color: theme-color(primary);
              svg {
                fill: rgba(theme-color(primary), .2);
              }
              .link-title {
                color: theme-color(primary);
              }
            }
          }
        }
      }
    }
  }
.habilidoso {
    background: theme-color(success) !important;
}
.comunicador {
    background: theme-color(danger) !important;
}
.organizador {
    background: theme-color(info) !important;
}
.originario {
    background: theme-color(dark) !important;
}
.calidad {
    background: theme-color(warning) !important;
}